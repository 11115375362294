import React from 'react'
import './NextEvents.css'
import Slider from '../../Slider/Slider';

import Miura from '../../../assets/images/nextEvents/miura.png'
import sasha from '../../../assets/images/nextEvents/sashadg.png'
import allinone from '../../../assets/images/nextEvents/ALLINONE_POST_SALAURBANA_131124.png'


const nextEvents = [
  {
    image: Miura,
    title: 'Miura',
    buy: 'https://boletos.miuraboxing.com'
  },

  {
    image:sasha ,
    title: 'Sasha & John Digweed',
    buy: 'https://sd25.boletia.com'
  },
  {
    image:allinone ,
    title: 'Sub Zero Project',
    buy: 'https://www.passline.com/eventos/sub-zero-project-mexico-city1'
  },


];

export default function NextEvents() {
  return (
    <div className='NextEvents'>
      <Slider 
        items={nextEvents}
        handleClick={() => console.log('do nothing')}
      />
    </div>
  )
}
